.text-input {
    background-color: rgba(200, 200, 200, 0.2);
    border: 2px solid transparent;
    border-radius: 6px 6px 6px 6px;
    -o-border-radius: 6px 6px 6px 6px;
    -ms-border-radius: 6px 6px 6px 6px;
    -moz-border-radius: 6px 6px 6px 6px;
    -khtml-border-radius: 6px 6px 6px 6px;
    -webkit-border-radius: 6px 6px 6px 6px;
    color: #ffffff;
    font-family: VT323, Roboto, Open Sans, Arial, Helvetica, sans-serif;
    font-size: 24px;
    outline: none;
    -o-outline: none;
    -ms-outline: none;
    -moz-outline: none;
    -khtml-outline: none;
    -webkit-outline: none;
    padding: 10px 14px 10px 14px;
    transition: background-color 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: background-color 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
    -ms-transition: background-color 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: background-color 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
    -khtml-transition: background-color 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-transition: background-color 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 100%;
}

.text-input.medium {
    font-size: 20px;
    padding: 8px 12px 8px 12px;
}

.text-input.small {
    font-size: 16px;
    padding: 6px 10px 6px 10px;
}

.text-input::placeholder {
    color: rgba(255, 255, 255, 0.75);
}

.text-input:hover {
    background-color: rgba(200, 200, 200, 0.4);
}

.text-input:focus {
    background-color: rgba(200, 200, 200, 0.2);
    border-color: #ffffff;
    transition: none;
    -o-transition: none;
    -ms-transition: none;
    -moz-transition: none;
    -khtml-transition: none;
    -webkit-transition: none;
}
