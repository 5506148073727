dt.pair.with-title {
    cursor: help;
}

dt.pair.name-click {
    cursor: pointer;
}

dd.pair.value-click {
    cursor: pointer;
}
