.footer > .footer-background {
    bottom: 0px;
    filter: blur(1.0rem);
    -o-filter: blur(1.0rem);
    -ms-filter: blur(1.0rem);
    -moz-filter: blur(1.0rem);
    -khtml-filter: blur(1.0rem);
    -webkit-filter: blur(1.0rem);
    height: 40px;
    left: 0px;
    position: fixed;
    width: 100%;
    z-index: 1;
}

.footer > .footer-contents {
    bottom: 0px;
    height: 40px;
    left: 0px;
    line-height: 40px;
    padding: 0px 0px 0px 0px;
    position: fixed;
    text-align: center;
    width: 100%;
    z-index: 1;
}
