dt.pair-state {
    margin-top: 0px;
}

dd.pair-state {
    margin-top: 0px;
}

dd.pair-state {
    font-size: 0px;
}

.pair-state .pair-state-description {
    color: #f0f0f0;
    font-size: 16px;
    margin-top: 6px;
}

.pair-state .pair-state-buttons {
    font-size: 17px;
    margin-top: 26px;
}

.pair-state .pair-state-buttons > .link {
    margin-right: 8px;
}

.pair-state .pair-state-buttons > .link:last-child {
    margin-right: 0px;
}

.pair-state .pair-state-buttons > .link-separator {
    margin-right: 8px;
}

.pair-state .pair-state-buttons > .link-separator:first-of-type {
    display: none;
}

.pair-state .pair-state-thumbnail {
    background: #000000;
    border: 1px solid #ffffff;
    border-radius: 6px 6px 6px 6px;
    image-rendering: pixelated;
    padding: 5px 5px 5px 5px;
    width: 100px;
}
