.audio-gb > .section {
    display: inline-block;
    vertical-align: top;
}

.audio-gb > .section > .audio-wave {
    display: inline-block;
    margin-right: 5px;
}

.audio-gb > .section > .audio-wave {
    display: inline-block;
    margin-right: 5px;
}

.audio-gb > .section > .audio-wave.master {
    display: block;
    margin-bottom: 16px;
}

.audio-gb > .section > .audio-wave.selector {
    cursor: pointer;
}

.audio-gb > .section > .audio-wave.disabled {
    opacity: 0.6;
    -o-opacity: 0.6;
    -ms-opacity: 0.6;
    -moz-opacity: 0.6;
    -khtml-opacity: 0.6;
    -webkit-opacity: 0.6;
}

.audio-gb > .section > .audio-wave > h4 {
    margin: 4px 0px 4px 0px;
}
