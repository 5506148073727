.display {
    max-width: 100%;
}

.display.fullscreen {
    align-items: center;
    background-color: #000000;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0px;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 6;
}

.display > .magnify-button {
    cursor: pointer;
    display: inline-block;
    transition: transform 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: transform 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
    -ms-transition: transform 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: transform 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
    -khtml-transition: transform 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-transition: transform 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.display > .magnify-button:hover {
    transform: scale(1.3, 1.3);
    -o-transform: scale(1.3, 1.3);
    -ms-transform: scale(1.3, 1.3);
    -moz-transform: scale(1.3, 1.3);
    -khtml-transform: scale(1.3, 1.3);
    -webkit-transform: scale(1.3, 1.3);
}

.display > .magnify-button:active {
    transform: scale(1.0, 1.0);
    -o-transform: scale(1.0, 1.0);
    -ms-transform: scale(1.0, 1.0);
    -moz-transform: scale(1.0, 1.0);
    -khtml-transform: scale(1.0, 1.0);
    -webkit-transform: scale(1.0, 1.0);
}

.display > .display-minimize {
    bottom: 22px;
    display: none;
    position: absolute;
    right: 22px;
    user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

@media only screen and (max-width: 1120px) {
    .display > .display-minimize {
        bottom: initial;
        top: 22px;
    }
}

.display > .display-minimize > img {
    height: 32px;
    width: 32px;
}

.display.fullscreen > .display-minimize {
    display: block;
}

.display > .display-frame {
    background-color: #000000;
    border: 2px solid #58b09c;
    border-radius: 8px 8px 8px 8px;
    -o-border-radius: 8px 8px 8px 8px;
    -ms-border-radius: 8px 8px 8px 8px;
    -moz-border-radius: 8px 8px 8px 8px;
    -khtml-border-radius: 8px 8px 8px 8px;
    -webkit-border-radius: 8px 8px 8px 8px;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.6);
    -o-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.6);
    -ms-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.6);
    -khtml-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.6);
    font-size: 0px;
    padding: 8px 8px 8px 8px;
}

@media only screen and (max-width: 1120px) {
    .display > .display-frame {
        box-shadow: none;
        -o-box-shadow: none;
        -ms-box-shadow: none;
        -moz-box-shadow: none;
        -khtml-box-shadow: none;
        -webkit-box-shadow: none;
    }
}

.display.fullscreen > .display-frame {
    border: none;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.24);
    -o-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.24);
    -ms-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.24);
    -khtml-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.24);
    -webkit-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.24);
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}

.display > .display-frame > .display-canvas {
    image-rendering: pixelated;
    max-width: 100%;
    outline: none;
    -o-outline: none;
    -ms-outline: none;
    -moz-outline: none;
    -khtml-outline: none;
    -webkit-outline: none;
    transition: width 0.5s cubic-bezier(0.075, 0.82, 0.165, 1), height 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: width 0.5s cubic-bezier(0.075, 0.82, 0.165, 1), height 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -ms-transition: width 0.5s cubic-bezier(0.075, 0.82, 0.165, 1), height 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: width 0.5s cubic-bezier(0.075, 0.82, 0.165, 1), height 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -khtml-transition: width 0.5s cubic-bezier(0.075, 0.82, 0.165, 1), height 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-transition: width 0.5s cubic-bezier(0.075, 0.82, 0.165, 1), height 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
