.keyboard-help {
    font-size: 18px;
    line-height: 22px;
    list-style: none;
    margin: 0px 0px 0px 0px;
    padding-left: 0px;
}

.keyboard-help > li {
    margin: 12px 0px 12px 0px;
}

.keyboard-help .key-container {
    display: inline-block;
    margin-right: 18px;
    min-width: 86px;
    text-align: right;
}

.keyboard-help .key {
    border: 2px solid #ffffff;
    border-radius: 6px 6px 6px 6px;
    -o-border-radius: 6px 6px 6px 6px;
    -ms-border-radius: 6px 6px 6px 6px;
    -moz-border-radius: 6px 6px 6px 6px;
    -khtml-border-radius: 6px 6px 6px 6px;
    -webkit-border-radius: 6px 6px 6px 6px;
    display: inline-block;
    font-size: 16px;
    margin-right: 8px;
    min-width: 34px;
    padding: 1px 8px 1px 8px;
    text-align: center;
}

.keyboard-help .key:last-child {
    margin-right: 0px;
}

.faqs-help > h3 {
    margin: 0px 0px 6px 0px;
}

.faqs-help > p {
    line-height: 20px;
    margin: 0px 0px 22px 0px;
}
