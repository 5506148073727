.tiles-gb > .canvas {
    background-color: #000000;
    border: 2px solid #58b09c;
    border-radius: 8px 8px 8px 8px;
    -o-border-radius: 8px 8px 8px 8px;
    -ms-border-radius: 8px 8px 8px 8px;
    -moz-border-radius: 8px 8px 8px 8px;
    -khtml-border-radius: 8px 8px 8px 8px;
    -webkit-border-radius: 8px 8px 8px 8px;
    padding: 8px 8px 8px 8px;
}

.tiles-gb > .canvas.content-box {
    box-sizing: content-box;
    -o-box-sizing: content-box;
    -ms-box-sizing: content-box;
    -moz-box-sizing: content-box;
    -khtml-box-sizing: content-box;
    -webkit-box-sizing: content-box;
}
